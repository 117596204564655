<template>
  <section id="templatesList">
    <h1>{{ $t('templates') }}</h1>
    <h5 class="text-primary">
      {{ $t('templatesInfo') }}
    </h5>
    <b-row class="match-height mt-2">
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showTemplate"
        >
          <b-card-header>
            <b-card-title />
            <div
              class="d-flex"
              style="place-content: flex-end;"
            >
              <b-button
                variant="primary"
                @click="addTemplate()"
              >
                <feather-icon
                  icon="FolderPlusIcon"
                  size="18"
                />
                {{ $t('addTem') }}
              </b-button>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row v-if="allTemplates.length != 0">
              <b-col
                v-for="folder in allTemplates"
                :id="folder.node.id"
                :key="folder.node.id"
                class="mt-2"
                md="6"
                lg="4"
                xl="3"
                sm="12"
              >
                <b-card
                  class="cardContent"
                  no-body
                  @click.stop.prevent="editTemplate(folder.node.id)"
                >
                  <b-img-lazy
                    :src="buildImageUrl(folder)"
                    class="card-img-top cursor-pointer"
                    img-alt="Card image"
                    fluid-grow
                    @error="errorImg"
                  />
                  <b-card-header>
                    <div id="acortarPadre">
                      <b-card-title
                        id="acortar"
                        class="mb-25"
                      >
                        <span
                          v-if="folder.node.isActive"
                          class="mr-50 bullet bullet-success bullet-sm"
                        />

                        <span
                          v-else
                          class="mr-50 bullet bullet-danger bullet-sm"
                        />
                        {{ folder.node.name }}
                      </b-card-title>
                    </div>
                  </b-card-header>
                  <b-card-body>
                    <div class="actions">
                      <div class="action">
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template #button-content>
                            <feather-icon
                              color="white"
                              icon="MoreVerticalIcon"
                              size="20"
                            />
                          </template>
                          <b-dropdown-item @click.stop.prevent="editTemplate(folder.node.id)">
                            <feather-icon
                              icon="Edit2Icon"
                              size="18"
                            />
                            {{ $t("editTemplate") }}
                          </b-dropdown-item>
                          <b-dropdown-item @click.stop.prevent="deleteTemplate(folder.node.id)">
                            <feather-icon
                              icon="DeleteIcon"
                              size="18"
                            />
                            {{ $t("deltTemplate") }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col>
                <b-jumbotron
                  bg-variant="transparent"
                  border-variant="primary"
                  :header="$t('Createtemplates')"
                  class="text-center"
                >
                  <p class="text-primary">
                    {{ $t('noTemplates') }}
                  </p>
                </b-jumbotron>
              </b-col>
            </b-row>
          </b-card-body>
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal
      ref="template-view"
      size="lg"
      hide-footer
      :title="$t('templates')"
    >
      <div>
        <templateView
          :id="idTemplate"
          :create-bool="createBool"
          @refresh="getTemplate()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="hideModal"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { messageError, showToast } from '@/store/functions'
import { required } from '@validations'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BRow,
  BCol,
  BCardHeader,
  BCardTitle,
  BJumbotron,
  BCardBody,
  BImgLazy,
  BDropdownItem,
  BButton,
  BCard,
  BOverlay,
  BDropdown,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import templateView from './templatesView.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BImgLazy,
    BJumbotron,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    templateView,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      createBool: false,
      userData: getUserData(),
      idTemplate: null,
      showTemplate: false,
      headers: {},
      allTemplates: [],
      locales: [],
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }

    this.getTemplate()
  },
  methods: {
    buildImageUrl(folder) {
      return folder.node.sampleImage == null || folder.node.sampleImage.length === 0
        ? noCover
        : folder.node.sampleImage
    },
    addTemplate() {
      this.idTemplate = null
      this.createBool = true

      this.$refs['template-view'].show()
    },
    editTemplate(id) {
      this.createBool = false
      this.idTemplate = id
      this.$refs['template-view'].show()
    },
    hideModal() {
      this.$refs['template-view'].hide()
    },
    getTemplate() {
      this.hideModal()
      this.showTemplate = !this.showTemplate
      const { headers } = this

      axios
        .post('', {
          query: `
           query{
            allTemplates {
              edges {
                node {
                  id
                  name
                  key
                  menuLocationTv
                  isActive
                  sampleImage
                }
              }
            }
          }
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.allTemplates = res.data.data.allTemplates.edges
          this.showTemplate = !this.showTemplate
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    deleteTemplate(id) {
      this.showTemplate = !this.showTemplate
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `
          mutation{
            deleteTemplates(id:"${id}"){
              found
              deletedId
            }
          }
          `,
            })
            .then(res => {
              messageError(res, this)
              this.showTemplate = !this.showTemplate

              this.getTemplate()
              showToast(this.$t('success'), 1, this)
            })
            .catch(err => {
              console.error(err)
              this.showTemplate = !this.showTemplate

              showToast(this.$t('error'), 2, this)
            })
        } else {
          this.showTemplate = false
        }
      })
    },

  },
}
</script>

<style scoped>
#templatesList .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#templatesList .cardContent img {
  height: 11vw;
  object-fit: cover;

}

@media screen and (max-width: 767px) {
  #templatesList .cardContent img {
    height: 40vw;
    object-fit: cover;
  }
}

@media screen and (min-width: 767px) {

  #templatesList .cardContent img {
    height: 23vw;
    object-fit: cover;

  }
}

@media screen and (min-width: 1240px) {

  #templatesList .cardContent img {
    height: 12vw;
    object-fit: cover;

  }
}

#templatesList .card-body {
  padding: 1rem;
}

#templatesList .card-body h4 {
  font-size: 1rem !important;
}

#templatesList .card-header {
  padding: 1rem;
}

#templatesList .card-header h4 {
  font-size: 1rem !important;
}

#templatesList .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

#templatesList .dropContainer {
  border: 3px dashed;
}

#templatesList .list-group-item {
  transition: all 1s;
}

#templatesList #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#templatesList #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.manualOrder {
  border: 5px dashed #e69d5d;
  background: repeating-linear-gradient(-55deg,
      #283046,
      #283046 10px,
      #161d31 10px,
      #161d31 20px);
}

@-webkit-keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }

  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }

  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@-webkit-keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

@keyframes hang-on-hover-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}

.hang-on-hover {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hang-on-hover:hover,
.hang-on-hover:focus,
.hang-on-hover:active {
  -webkit-animation-name: hang-on-hover-sink, hang-on-hover;
  animation-name: hang-on-hover-sink, hang-on-hover;
  -webkit-animation-duration: .3s, 1.5s;
  animation-duration: .3s, 1.5s;
  -webkit-animation-delay: 0s, .3s;
  animation-delay: 0s, .3s;
  -webkit-animation-timing-function: ease-out, ease-in-out;
  animation-timing-function: ease-out, ease-in-out;
  -webkit-animation-iteration-count: 1, infinite;
  animation-iteration-count: 1, infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-direction: normal, alternate;
  animation-direction: normal, alternate;
}
</style>
